/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #home {
    margin-left: 3%;
    margin-right: 3%;
  }
  #salesFunnelchartControle {
    width: 450px;
  }
  #salesFunnelControl {
    width: 450px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #home {
    margin-left: 3%;
    margin-right: 3%;
  }
  #salesFunnelchartControle {
    width: 450px;
  }
  #salesFunnelControl {
    width: 450px;
  }
}

.red:focus {
  color: #0069D9;
}


#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
   /* border: 1px solid #ddd;  */
   padding: 10px;
}

#customers tr:nth-child(even){background-color: #fff;}

#customers tr:nth-child(odd){background-color: #FAFAFA;}


/* #customers tr:hover {background-color: #ddd;} */

#customers th {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #FAFAFA;
  color: black;
  margin-bottom: 100px !important;
}